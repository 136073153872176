import { Options, setup, Vue } from 'vue-class-component';
import { Login } from "../../services";
import authBloc from "@/modules/auth/services/bloc";
import { widget } from "@cems-eco/core/src/utils/widget";
import { Validators } from "@cems-eco/core/src/utils/validator";
import guard from '@/guard';
import environment from '@/config';

export default class LoginPage extends Vue {
    loginBloc = authBloc
    rules: any = {
        email: { type: "email" },
        password: { type: "string", min: 1, }
    };

    login = new Validators<Login>({
        value: new Login,
        rules: this.rules
    });

    type = "password";
    icon = "far fa-eye";

    async created() {
        guard.checkAuthentication();
    }
    logo() {
        return environment.white_label.asset.logo;
    }

    showPasswordToggle() {
        if (this.type == "password") {
            this.type = "text";
            this.icon = "far fa-eye-slash";
            return;
        }
        
        this.type = "password";
        this.icon = "far fa-eye";
    }

    async authenticate() {
        const loader: any = await widget.loading();
        try {
            loader.showLoading();
            await guard.checkProviderStatus();
            const response = await this.loginBloc.login(this.login.value);
            await this.saveCredentialToStorage(response);
            guard.checkAuthentication();
        } catch (e) {
            loader.close();
            console.log(e);
            widget.alertError(e);
        }
    }

    async saveCredentialToStorage(data: any) {
        await this.loginBloc.saveStorage(data);
    }

}

