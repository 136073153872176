import { Activity, activityApi } from "./index"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils/helper";

export function activityFactory() {
    const module = 'activity';
    const api = activityApi;
    const base_url = environment.API_URL + '/' + module;

    async function getActivities() : Promise<[Activity]> {
        let response = await api.all()
        return response.data.data;
    }

    async function getActivity(id: any): Promise<Activity> {
        let response = await api.first(id)
        return response.data;
    }

    async function createActivity(activity: Activity): Promise<Activity> {
        let response = await api.create(activity)
        return response.data;
    }

    async function updateActivity(activity: Activity): Promise<Activity> {
        let response = await api.edit(activity.id, activity)
        return response.data;
    }

    // FIXME: not working
    async function destroyActivity(id: any): Promise<void> {
        let response = await api.destroy(id)
        return response.data;
    }

    async function logout(id: any): Promise<void> {
        let response = await api.logout(id)
        return response.data;
    }

    async function searchParams(activity: Activity): Promise<string> {
        const uri = helper.toSerializeDatatable(activity);
        return base_url + encodeURI(uri);
    }

    return {
        base_url,
        api,
        getActivities,
        getActivity,
        createActivity,
        updateActivity,
        destroyActivity,
        logout,
        searchParams
    }

}

