import { Vue, setup } from "vue-class-component";
import { Activity, activityFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import moment from 'moment';
import environment from "@/config";
import storage from "@/storage";

export default class ActivityPage extends Vue {

    activityBloc = setup(() => activityFactory())
    options = {}
    events: object[] = []
    dt: any;
    isSearching = true;
    activity = new Activity;

    userOptions: any = {};

    created() {
        this.userOptions = this.initOption(environment.API_URL + '/user/select2');
    }

    mounted() {
        this.builDataTable();
        widget.datePicker('start-date');
        widget.datePicker('end-date');
    }

    initOption(url: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async builDataTable() {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
        const loader: any = await widget.loading();
        loader.showLoading();
        this.options = {
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: this.activityBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            mobileView: {
                header: {
                    title: function (data: any) {
                        return data.activity;
                    },
                }
            },
            columns: [
                {
                    data: 'user.name',
                    title: 'Name',
                    mobileView: true,
                },
                {
                    data: 'activity',
                    title: 'Activity',
                    mobileView: false,
                },
                {
                    data: 'created_at',
                    title: 'Created At',
                    mobileView: true,
                    render: function (data, type, row) {
                        return moment(data).format('DD/MM/YYYY h:mm:ss');
                    }
                },
            ]
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }

    userSelectChanged(data: any[]) {
        this.activity.user_id = data[0].id;
    }

    openSearch() {
        this.isSearching = !this.isSearching;
        this.builDataTable();
    }

    async searching() {
        const searchUrl = await this.activityBloc.searchParams(this.activity);
        this.dt.ajax.url(searchUrl);
        this.dt.ajax.reload();
    }
}

