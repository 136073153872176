export interface IActivity {
    readonly id: any;
    user_id: string
    activity: string
}

export class Activity {
    public id!: any;
    public _method!: string;
	public user_id!: string;
	public activity!: string;
}

